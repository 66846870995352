/* eslint-disable max-classes-per-file */
import { Controller } from 'stimulus'
import debounce from 'lodash/debounce'
import mediaFrom from '../../_js/utils/media-from'
import { BREAKPOINTS } from '../../_js/base/consts'

export default class DesignResourcesController extends Controller {
  static targets = ['filter', 'contentWrapper', 'seeAllWrapper', 'pinned', 'filtersWrapper', 'horizontalLine']

  static values = {
    env: String,
  }

  currentCategory = 'see-all'

  connect() {
    this.orderItems() // Order pinned items to the top of the grid

    this.syncScroll() // Sync scroll for all filter divs

    if (this.envValue === 'web-app') {
      if (localStorage.getItem('theme') === '"dark"' || localStorage.getItem('theme') === 'dark') {
        document.body.classList.add('design-resources--dark')
        document.documentElement.classList.add('dark-mode') // Add this to force the scrollbar to go dark if theme is dark
      }
    } else if (this.envValue === 'mac-app') {
      this.resizePinnedItems() // Resize pinned items for the active grid
      window.addEventListener('resize', this.resizePinnedItems.bind(this))

      if (this.hasHorizontalLineTarget) {
        window.addEventListener('scroll', this.addHorizontalLine.bind(this))
      }
    }
  }

  disconnect() {
    if (this.hasHorizontalLineTarget) {
      window.removeEventListener('scroll', this.addHorizontalLine.bind(this))
    }
  }

  changeCategory(event) {
    const clickTarget = event.target
    const chosenCategory = clickTarget.dataset.category

    const activeDivs = this.contentWrapperTargets.filter((element) => element.dataset.category === this.currentCategory)
    const categoryDivs = this.contentWrapperTargets.filter((element) => element.dataset.category === chosenCategory)

    const activeButtons = this.filterTargets.filter((element) => element.dataset.category === this.currentCategory)

    activeButtons.forEach((button) => button.classList.toggle('is-active'))
    activeDivs.forEach((div) => div.classList.toggle('content__items--is-active'))

    const clickedButtons = this.filterTargets.filter((element) => element.dataset.category === chosenCategory)
    clickedButtons.forEach((button) => button.classList.toggle('is-active'))
    categoryDivs.forEach((div) => {
      div.classList.toggle('content__items--is-active')
      div.classList.add('entrance', 'entrance--fadeUp')
    })

    if (categoryDivs.length > 0 && clickTarget.closest('.top-bar')) {
      const mainFiltersRow = document.getElementById('main-filters-row')
      if (mainFiltersRow) {
        const navHeight = 64
        const extraHeight = mediaFrom(BREAKPOINTS.VIEWPORT_L) ? 36 : 36 + navHeight

        window.scrollTo({
          top: this.envValue == 'web-app' ? 0 : mainFiltersRow.offsetTop - extraHeight, // Scroll to top of page if web app, top of filters if not
          behavior: 'smooth',
        })
      }
    }

    this.currentCategory = chosenCategory

    if (this.envValue === 'mac-app') {
      // Trigger resizePinnedItems for the active grid
      this.resizePinnedItems()
      window.removeEventListener('resize', this.resizePinnedItems)
      window.addEventListener('resize', this.resizePinnedItems.bind(this))
    }
  }

  syncScroll() {
    const filterDivs = this.filtersWrapperTargets

    const handleScroll = debounce((e) => {
      filterDivs.forEach((otherDiv) => {
        if (otherDiv !== e.target) {
          otherDiv.scrollLeft = e.target.scrollLeft
        }
      })
    }, 100)

    filterDivs.forEach((div) => {
      div.addEventListener('scroll', handleScroll)
    })
  }

  orderItems() {
    this.pinnedTargets.forEach((pinned) => {
      const gridParent = pinned.closest('.content__items__grid')
      if (gridParent) {
        const pinnedItemsDiv = gridParent.querySelector('.pinned-items')
        if (pinnedItemsDiv && !pinnedItemsDiv.contains(pinned)) {
          pinnedItemsDiv.appendChild(pinned)
        }
      }
    })
  }

  // eslint-disable-next-line class-methods-use-this
  resizePinnedItems() {
    const activeGrid = document.querySelector('.content__items--is-active .content__items__grid')
    if (!activeGrid) return

    const pinnedItems = activeGrid.querySelector('.pinned-items')
    const pinnedCards = pinnedItems.querySelectorAll('.content-card')

    if (pinnedItems.length < 2) return

    // Directly target the first and second non-pinned items in the activeGrid
    const firstItem = activeGrid.children[1]
    const secondItem = activeGrid.children[2]

    if (firstItem && secondItem) {
      const gap = secondItem.getBoundingClientRect().left - firstItem.getBoundingClientRect().right

      const gridPadding = 20

      // Calculate the available width excluding the padding
      const availableWidth = activeGrid.clientWidth - gridPadding * 2 - gap

      const cardWidth = availableWidth / 2

      pinnedCards.forEach((card, index) => {
        card.style.width = `${cardWidth}px`
        if (index === 1) {
          card.style.marginLeft = 'auto'
        } else {
          card.style.marginLeft = '0'
        }
      })
    }
  }

  // eslint-disable-next-line class-methods-use-this
  loadMore(event) {
    const buttonParentDiv = event.target.closest('div')
    const contentItemsGrid = buttonParentDiv.querySelector('.content__items__grid')
    const loadMoreButton = event.target

    // Calculate the full height of the content and add 24px for padding
    const fullHeight = contentItemsGrid.scrollHeight + 24

    // Set the maxHeight to the current height to enable transition
    contentItemsGrid.style.maxHeight = `${contentItemsGrid.clientHeight}px`

    // Remove the shortened class and add the expanded class
    buttonParentDiv.classList.add('content__items--full-height')

    // Animate maxHeight to the full height
    contentItemsGrid.style.maxHeight = `${fullHeight}px`

    contentItemsGrid.addEventListener('transitionend', () => {
      // Hide the load more button and make the maxHeight 100%
      loadMoreButton.style.display = 'none'
      contentItemsGrid.style.maxHeight = '100%'
      contentItemsGrid.classList.add('content__items__grid--gradient-hidden')
    })
  }

  addHorizontalLine() {
    if (window.scrollY > 0) {
      this.horizontalLineTarget.classList.add('is-visible')
    } else {
      this.horizontalLineTarget.classList.remove('is-visible')
    }

    if (document.querySelector('.top-bar').classList.contains('top-bar--visible')) {
      this.horizontalLineTarget.classList.add('no-shadow')
    } else {
      this.horizontalLineTarget.classList.remove('no-shadow')
    }
  }
}
