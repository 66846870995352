import { Controller } from 'stimulus'
import throttle from 'lodash/throttle'

export default class TopBar extends Controller {
  static targets = ['topBar']

  static values = {
    intersectTarget: String,
    hideTarget: String,
  }

  topBarHeight = null

  intersectionTargetElement = null

  connect() {
    // Set the element against which we'll compare to toggle the top-bar visibility
    this.intersectionTargetElement = document.querySelector('#' + this.intersectTargetValue)

    // Set the element after which we don't want the top bar to show up
    if (this.hideTargetValue) {
      this.hideTargetElement = document.querySelector('#' + this.hideTargetValue)
    }

    // Set the initial translate position
    this.toggleVisibility()

    this.toggleVisibility = throttle(this.toggleVisibility.bind(this), 200)
    this.setTopBarHeight = throttle(this.setTopBarHeight.bind(this), 500)

    window.addEventListener('resize', this.setTopBarHeight)
    window.addEventListener('scroll', this.toggleVisibility)
  }

  disconnect() {
    window.removeEventListener('resize', this.setTopBarHeight)
    window.removeEventListener('scroll', this.toggleVisibility)
  }

  // Save the height of the bar to correctly calculate the translateY
  setTopBarHeight() {
    this.topBarHeight = this.topBarTarget.clientHeight
  }

  toggleVisibility() {
    const intersectionDistance = this.intersectionTargetElement.getBoundingClientRect().y
    const hideTargetDistance = this.hideTargetElement ? this.hideTargetElement.getBoundingClientRect().y : null

    // If we have reached the intersected target and we haven't gone past the hide target
    if (
      intersectionDistance <= this.topBarHeight &&
      (!hideTargetDistance || (hideTargetDistance !== null && hideTargetDistance > this.topBarHeight))
    ) {
      this.topBarTarget.style.transform = 'translateY(0)'
      this.topBarTarget.style.visibility = 'visible'
      this.topBarTarget.classList.add('top-bar--visible')
    } else {
      // Adding 5 here as a safety distance so the item hides completely
      const translateAmount = this.topBarHeight + 5
      this.topBarTarget.style.transform = 'translateY(-' + translateAmount + 'px)'

      if (window.scrollY <= this.topBarHeight) {
        this.topBarTarget.style.visibility = 'hidden'
        this.topBarTarget.classList.remove('top-bar--visible')
      }
    }
  }
}
