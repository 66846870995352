import { Controller } from 'stimulus'

export default class DownloadsController extends Controller {
  static targets = ['downloadLink']

  hasDownloaded = false

  connect() {
    if (GLOBALS.ENV === 'development') return

    const downloadLink = this.downloadLinkTarget
    const videoPlayer = document.querySelector('.video-player')

    // Check initial state
    if (videoPlayer.readyState >= 3) {
      this.initiateDownload(downloadLink)
      return
    }

    // Add loadedmetadata event which fires regardless of autoplay
    videoPlayer.addEventListener('loadedmetadata', () => this.initiateDownload(downloadLink), { once: true })

    // Keep existing events as fallback
    videoPlayer.addEventListener('play', () => this.initiateDownload(downloadLink), { once: true })
    videoPlayer.addEventListener('canplay', () => this.initiateDownload(downloadLink), { once: true })
  }

  initiateDownload(downloadLink) {
    if (!this.hasDownloaded) {
      this.hasDownloaded = true
      window.setTimeout(() => downloadLink.click(), 1000)
    }
  }
}
